import { CategoriesTypeT } from "services/services.service";
import { FaCheck } from 'react-icons/fa';
import styles from './CardCategories.module.scss'
import { memo, useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useAppSelector } from "lib/useAppSelect";
import { FormProduct } from "./FormProduct";

type Props = {
    categories?: CategoriesTypeT[];
    type?: string;
    type_id?: string;
}

export const CardCategories: React.FC<Props> = memo(({ categories, type, type_id }) => {
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const [product, setProduct] = useState<CategoriesTypeT | null>()
    const [opened, { open, close }] = useDisclosure(false);

    const handlerChoiceProduct = (item: CategoriesTypeT) => {
        setProduct(item);
        open();
    }

    useEffect(() => {
        setProduct(null)
    }, [close])

    return (
        <div className={styles.wrapper}>
            {
                !!categories?.length && categories.map((item) => {
                    return (
                        <div key={item.id} className={styles.card}

                            onClick={() => handlerChoiceProduct(item)}
                        >
                            <div className={styles.header}>
                                {item.category}
                            </div>
                            {
                                !!item?.points?.length &&
                                <div className={styles.points}>
                                    {item?.points.map((list) => (
                                        <div className={styles.point}>
                                            <FaCheck color={"#4CAF50"} size={10} />{list}
                                        </div>
                                    ))}</div>
                            }
                            {!!item?.discount_percentage && <div className={styles.row}>Discount: {item?.discount_percentage}%</div>}
                            {!!item?.price_per_unit && <div className={styles.row}>Price per unit: {item?.price_per_unit || 0}$</div>}
                            {!!item?.discounted_price && <div className={styles.row}>Discounted price: {item?.discounted_price}$</div>}
                        </div>
                    )
                })
            }
            <Modal
                opened={opened}
                closeOnEscape={false}
                closeOnClickOutside={false}
                onClose={close}
                title={<div className={styles.title}>{product?.service_name}</div>}
                size={product?.video_link ? 1200 : "lg"}
                fullScreen={isMobile}
                classNames={{
                    body: styles.modalBody,
                    header: styles.modalHeader,
                    content: styles.modalContent,
                    close: styles.modalCloseButton,
                    overlay: styles.modalOverlay
                }}
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <FormProduct product={product} type_id={type_id} closeAll={close} />
            </Modal>
        </div>
    )
})