import { useMutation, useQuery } from 'react-query';
import services, { CrateOrderT } from 'services/orders.service';

export const useOrdersAllQuery = (ordering?: string) => {
  return useQuery(['ordersList', ordering], () => services.all(ordering), {
    keepPreviousData: true, // Сохраняем предыдущие данные, чтобы избежать пустого состояния
  });
};


export const useOrdersGetQuery = () => {
  return useMutation(async (id: number) => await services.orderId(id));
};

export const useOrdersCreateQuery = () => {
  return useMutation(async (data: CrateOrderT) => await services.create(data));
};
