import { useUsersMeQuery } from 'lib/users/useUsersMeQuery';
import styles from './Profile.module.scss';
import { removeToken } from 'services';
import { toggleAuthState } from 'store/authStateSlice';
import { RouteNames } from 'navigate/routeNames';
import { IoLogOutOutline } from 'react-icons/io5';
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'lib/useAppDispatch';
import { Button } from "components";
import { useAppSelector } from 'lib/useAppSelect';
import { PasswordProfile } from './PasswordProfile';
import { EmailProfile } from './EmailProfile';
import { useDisclosure } from '@mantine/hooks';

export const Profile: React.FC = () => {
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const { data: usersMe } = useUsersMeQuery();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const logInOut = () => {
        dispatch(toggleAuthState({ isLogin: false }));
        navigate(RouteNames.START);
        removeToken();
    }

    const [isOpenPassword, { open: openPassword, close: closePassword }] = useDisclosure(false);
    const [isOpenEmail, { open: openEmail, close: closeEmail }] = useDisclosure(false);

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                My Profile
            </div>
            <div className={styles.headSection}>
                Update Profile
            </div>
            <div className={styles.content}>
                <div className={styles.about}>
                    <span className={styles.options}>Email:&nbsp;</span>
                    <div className={styles.info}>
                        {usersMe?.email ?? ""}
                    </div>
                    <div className={styles.edit} onClick={openEmail}>
                        <MdModeEdit />
                    </div>
                </div>
                <div className={styles.about}>
                    <div className={styles.options}>Password:&nbsp;</div>
                    <div className={styles.info}>
                        ********
                    </div>
                    <div className={styles.edit} onClick={openPassword}>
                        <MdModeEdit />
                    </div>
                </div>
            </div>
            {
                isMobile &&
                <Button
                    onClick={logInOut}
                    type='button'
                    classNames='black'
                    content={
                        <div className={styles.exit}>
                            Log Out <IoLogOutOutline size={22} />
                        </div>
                    } />
            }
            <EmailProfile opened={isOpenEmail} close={closeEmail} />
            <PasswordProfile opened={isOpenPassword} close={closePassword} />
        </div>

    )
}
