import { FaTachometerAlt, FaBoxOpen, FaWallet, FaUser, FaQuestionCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import styles from './Menu.module.scss';
import { useDisclosure } from '@mantine/hooks';
import { Drawer } from '@mantine/core';
import { useServiceListQuery } from 'lib/service/useServicesQuery';
import { ServicesT } from 'services/services.service';
import { API_URL } from 'consts';



export const Menu: React.FC = () => {

    const [opened, { open, close }] = useDisclosure(false);

    const menuItems = [
        { name: 'Services', path: '/', icon: <FaTachometerAlt size={20} /> },
        { name: 'Add', path: '/orders', icon: <FaBoxOpen size={20} /> },
        { name: 'Balance', path: '/top-up', icon: <FaWallet size={20} /> },
        { name: 'Profile', path: '/profile', icon: <FaUser size={20} /> },
    ];

    const { data: list } = useServiceListQuery();

    const renderServiceIcon = (service: ServicesT) => {
        if (service?.icon_svg) {
            return <div className={styles.iconSvg}>
                <div dangerouslySetInnerHTML={{ __html: service.icon_svg }} />
            </div>
        }
        if (service?.icon_service) {
            return <img className={styles.iconImg} src={`${API_URL}/${service.icon_service}`} alt={`${service.name} icon`} />;
        }
        return <div></div>;
    };


    return (
        <div className={styles.content}>
            <Drawer
                position='bottom'
                opened={opened}
                onClose={close}
                classNames={{
                    header: styles.customDrawer,
                    content: styles.customDrawer,
                    close: styles.customCloseButton
                }}
            >
                <div className={styles.list}>
                    {!!list?.length &&
                        list.map((service) => {
                            return (
                                <NavLink
                                    key={service.id}
                                    to={`orders/${service.id}/${service.name}`}
                                    className={({ isActive }) =>
                                        isActive ? `${styles.activeType} ${styles.linkType} ` : styles.linkType
                                    }
                                >
                                    <div className={styles.iconService}>
                                        {renderServiceIcon(service)}
                                    </div>
                                    <span>{service.name}</span>
                                </NavLink>
                            );
                        })}
                </div>
            </Drawer>
            {menuItems.map((item, index) => {
                return (
                    <div key={item.path + index}>
                        {item.path === "/orders" ? <div
                            onClick={open}
                            className={styles.link}>
                            <span className={styles.icon}>
                                {item.icon}
                            </span>
                            <span className={styles.name}>
                                {item.name}
                            </span>
                        </div> :
                            <NavLink
                                to={item.path}
                                className={({ isActive }) => (isActive ? `${styles.active} ${styles.link} ` : styles.link)}
                            >
                                <span className={styles.icon}>
                                    {item.icon}
                                </span>
                                <span className={styles.name}>
                                    {item.name}
                                </span>
                            </NavLink>
                        }
                    </div>
                )
            }
            )}
        </div>
    )
}