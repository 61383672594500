import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'lib/useAppSelect';
import { RouteNames } from 'navigate/routeNames';
// import { useAuthQuery } from 'lib/auth/useAuthorizationQuery';
import styles from './NewPassword.module.scss';
import { Button, Input } from "components";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import toast from 'react-hot-toast';
import { useSendForNewPasswordQuery } from 'lib/auth/useAuthorizationQuery';

type NewPasswordT = {
    new_password: string;
    re_password: string;
}

export const NewPasswordPage: React.FC = () => {
    const {
        mutate: sendNewPassword,
        isSuccess,
        isLoading
    } = useSendForNewPasswordQuery();

    const [isPasswordFocused, setPasswordFocused] = useState(false);

    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm<NewPasswordT>();
    const { id, secret } = useParams<{ id: string; secret: string }>();
    const isValidUrl = secret && id && window.location.pathname.includes("activate/reset-password") && secret.includes("-");

    const navigate = useNavigate();
    const { isLogin } = useAppSelector((state) => state.authState);
    useEffect(() => {
        if (isSuccess) return (
            navigate(RouteNames.LOGIN)
        )
        if (isValidUrl && !isLogin) {
            if (!id || !secret) {
                navigate(RouteNames.LOGIN)
                toast.error('Something went wrong');
            }
        }
    }, [id, secret, isValidUrl]);

    const onSubmit: SubmitHandler<NewPasswordT> = (data) => {
        if (!data.new_password || !data.re_password || !id || !secret) return
        sendNewPassword(
            {
                uid: id,
                token: secret,
                new_password: data.new_password
            }
        )
    };

    const new_password = watch("new_password", "");

    return (
        <div className={styles.wrapper}>
            <div className={styles.name}>STARKSTORE</div>
            <div className={styles.card}>
                <div className={styles.header}>Set a New Password</div>
                <p className={styles.description}>Create a strong and secure password to access your account.</p>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.inputs}>
                    <div>
                        <Controller
                            name="new_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Password is required.",
                                minLength: {
                                    value: 8,
                                    message: "Password must be at least 8 characters long."
                                }
                            }}
                            render={({ field }) => (
                                <Input
                                    label="New Password"
                                    placeholder="Enter your new password"
                                    type="password"
                                    {...field}
                                    onFocus={() => setPasswordFocused(true)}
                                    onBlur={() => setPasswordFocused(false)}
                                />
                            )}
                        />
                        <p className={styles.errors}>
                            {isPasswordFocused && new_password.length < 8 && `Characters remaining: ${8 - new_password.length}`}
                            {!isPasswordFocused && errors.new_password && errors.new_password.message}
                        </p>
                    </div>
                    <div>
                        <Controller
                            name="re_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Password confirmation is required.",
                                validate: {
                                    matchesPreviousPassword: (value) => {
                                        const passwordValue = watch("new_password");
                                        return value === passwordValue || "Passwords do not match.";
                                    }
                                }
                            }}
                            render={({ field }) => (
                                <Input
                                    label="Confirm Password"
                                    placeholder="Re-enter your password"
                                    type="password"
                                    {...field}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors.re_password && errors.re_password.message}</p>
                    </div>
                    <div className={styles.login}>
                        <Button type="submit" content="Set Password" isLoading={isLoading} />
                        <Button type="button" content="Back to Login" classNames="black" onClick={() => navigate(RouteNames.LOGIN)} />
                    </div>
                </form>
            </div>
        </div>

    )
};
