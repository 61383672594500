import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { usePasswordResetQuery } from 'lib/auth/useAuthorizationQuery';

import styles from './PasswordReset.module.scss';
import { RouteNames } from 'navigate/routeNames';

interface EmailFormInput {
    email: string;
}

export const PasswordReset: React.FC = () => {
    const navigate = useNavigate();
    const {
        mutate: sendEmailForm,
        isSuccess,
        isLoading
    } = usePasswordResetQuery();
    const { control, handleSubmit, watch, formState: { errors } } = useForm<EmailFormInput>();

    const email = watch("email", "");

    const onSubmit: SubmitHandler<EmailFormInput> = (data) => {
        if (!data.email) return;
        sendEmailForm({ email: data.email });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.name}>STARKSTORE</div>
            <div className={styles.card}>
                {
                    isSuccess && <>
                        <div className={styles.header}>Check Your Email</div>
                        <div className={styles.text}>
                            We've sent you an email with a link to reset your password. Please check your inbox and follow the instructions to complete the process.
                        </div>
                        <div className={styles.buttonBack}>
                            <Button type="button" content="Back to Login" onClick={() => navigate(RouteNames.LOGIN)} />
                        </div>
                    </>
                }
                {
                    !isSuccess &&
                    <>
                        <div className={styles.header}>Reset Your Password</div>
                        <form onSubmit={handleSubmit(onSubmit)} className={styles.inputs}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Please enter your email address" }}
                                render={({ field }) => (
                                    <Input
                                        label="Email"
                                        placeholder="Enter your email address"
                                        type="email"
                                        required
                                        autoFocus={!email}
                                        {...field}
                                    />
                                )}
                            />
                            <p className={styles.errors}>{errors.email && errors.email.message}</p>
                            <div className={styles.login}>
                                <Button type="submit" content="Send Reset Link" isLoading={isLoading} />
                                <Button type="button" content="Back to Login" classNames="black" onClick={() => navigate(RouteNames.LOGIN)} />
                            </div>
                        </form>
                    </>
                }
            </div>
        </div>
    );
};
