import { useMutation } from 'react-query';
import {
  ActivationQueryT,
  LoginQueryT,
  SendEmailForResetPasswordQueryT,
  SendForNewPasswordQueryT,
  SignupQueryT,
} from 'types/auth';
import { useAppDispatch } from 'lib/useAppDispatch';
import authService from 'services/auth.service';
import { removeToken, setToken } from 'services';
import { toggleAuthState } from 'store/authStateSlice';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'navigate/routeNames';
import toast from 'react-hot-toast';

export const useLoginQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    async (data: LoginQueryT) => await authService.login(data),
    {
      retry: false,
      onSuccess: async (data) => {
        if ('access' in data && 'refresh' in data) {
          setToken(data.access, data.refresh);
          dispatch(toggleAuthState({ isLogin: true }));
          navigate(RouteNames.START);
        }
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.detail || 'An unexpected error occurred.';
        toast.error(errorMessage);
        dispatch(toggleAuthState({ isLogin: false }));
      },
    }
  );
};

export const usePasswordResetQuery = () => {
  return useMutation(
    async (data: SendEmailForResetPasswordQueryT) =>
      await authService.sendEmailForResetPassword(data),
    {
      retry: false,
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.detail || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};


export const useSendForNewPasswordQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    async (data: SendForNewPasswordQueryT) => await authService.sendForNewPassword(data),
    {
      retry: false,
      onSuccess: async () => {
        navigate(RouteNames.LOGIN);
        toast.success('Your password has been successfully updated.', { id: 'password-change-success' });
        dispatch(toggleAuthState({ isLogin: false }));
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.detail || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};


export const useAuthQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    async (data: ActivationQueryT) => await authService.activation(data),
    {
      retry: false,
      onSuccess: async () => {
        navigate(RouteNames.LOGIN);
        toast.success('Email verified', { id: 'email-confirmation' });
        dispatch(toggleAuthState({ isLogin: false }));
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.detail || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};

export const useSignupQuery = () => {
  const navigate = useNavigate();
  return useMutation(
    async (data: SignupQueryT) => await authService.signup(data),
    {
      // onSuccess: () => {
      // toast.success('Please click the link in the email');
      // navigate(RouteNames.START);
      // },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.password?.[0] ||
          error?.response?.data?.detail ||
          'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};

export const useLogoutQuery = () => {
  const dispatch = useAppDispatch();

  return useMutation(async () => authService.logout(), {
    onSuccess() {
      removeToken();
      dispatch(toggleAuthState({ isLogin: false }));
    },
  });
};
