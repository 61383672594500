import HttpService from './http.service';

export type AllOrdersT = {
  completed?: string;
  created_at?: string;
  id?: number;
  period?: string;
  quantity?: number;
  service?: string;
  service_option?: string;
  status?: string;
  total_price?: string;
  custom_data: { [key: string]: string };
};

export type CrateOrderT = {
  service: number;
  service_option: number;
  custom_data: { [key: string]: string };
  quantity: number;
  period: string;
  notes?: string;
  interval?: number | null;
};

export type OrderIdT = {
  created_at: string;
  custom_data: { [key: string]: string };
  id: number;
  interval: number;
  notes: string;
  period: string;
  quantity: number;
  service_name: string;
  status: string;
  total_price: string;
};

const OrdersService = {
  all: async (ordering?: string) => {
    const query = ordering ? `?ordering=${ordering}` : '';
    const { data }: { data: AllOrdersT[] } =
      await HttpService().get(`/order/all/${query}`);
    return data;
  },

  create: async (req: CrateOrderT) => {
    const { data }: { data: any } = await HttpService().post(
      `/order/create/`,
      req
    );
    return data;
  },

  orderId: async (order_id: number) => {
    const { data }: { data: OrderIdT } = await HttpService().get(
      `/order/${order_id}/`
    );
    return data;
  },
};

export default OrdersService;
