import HttpService from './http.service';

type GetBalanceHistoryT = {
  create_time: string;
  new_balance: string;
  old_balance: string;
};

export type SendFormInputT = {
  amount: string;
};

const BalanceService = {
  get: async () => {
    const { data }: { data: GetBalanceHistoryT[] } = await HttpService().get(
      '/user/balance-history/'
    );
    return data;
  },
  toUpBalance: async (req: SendFormInputT) => {
    const { data }: { data: any } = await HttpService().post(
      '/user/top-up/',
      req
    );

    return data;
  },
};

export default BalanceService;
