import { useMutation, useQuery } from 'react-query';
import balanceServices, { SendFormInputT } from 'services/balance.service';

export const useGetBalanceQuery = () => {
  return useQuery(['getBalanceHistory'], () => balanceServices.get());
};

export const useUpdateBalanceQuery = () => {
  return useMutation(
    async (data: SendFormInputT) => await balanceServices.toUpBalance(data)
  );
};
