import { useCalculatePriceQuery } from "lib/service/useServicesQuery";
import { useEffect, useState } from "react";

export const useUpdatePrice = (quantity: string, serviceOptionId: number) => {
    const [debouncedQuantity, setDebouncedQuantity] = useState(quantity);
    const { mutate: cala, data: price } = useCalculatePriceQuery()
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuantity(quantity);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [quantity]);

    useEffect(() => {
        if (debouncedQuantity && Number(debouncedQuantity) > 0) {
            cala({
                service_option_id: serviceOptionId,
                quantity: debouncedQuantity && Number(debouncedQuantity) || 0
            }
            );
        }
        if(debouncedQuantity && debouncedQuantity !== "0") return 
    }, [debouncedQuantity]);

    return price?.total_price || 0;
};
