import { useMutation, useQuery } from 'react-query';
import services, { CalcPriceT } from 'services/services.service';

export const useServiceListQuery = () => {
  return useQuery(['productList'], () => services.servicesList());
};

export const useServiceCategoriesQuery = (id?: string) => {
  return useQuery(
    ['productCategories', id],
    () => services.servicesCategories(id ?? '0'),
    {
      enabled: !!id,
    }
  );
};

export const useServicePopularQuery = () => {
  return useQuery(['popularServices'], () => services.popularServices());
};

export const useServiceCategoriesTypeQuery = () => {
  return useMutation((data: { service_id: string; category?: string }) =>
    services.servicesCategoryType(data)
  );
};

export const useCalculatePriceQuery = () => {
  return useMutation(
    async (data: CalcPriceT) => await services.servicesCalculatePrice(data)
  );
};
