import React, { useState } from 'react';
import styles from './Topup.module.scss';
import { useGetBalanceQuery } from 'lib/balance';
import { Button } from 'components';
import clsx from 'clsx';
import { formateDate } from 'utils';
import { useUsersMeQuery } from 'lib/users/useUsersMeQuery';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from "@mantine/core";
import { useAppSelector } from 'lib/useAppSelect';
import { FormUpBalance } from './components';

export const Topup: React.FC = () => {
    const { data } = useGetBalanceQuery();
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const [showAll, setShowAll] = useState(false);
    const { data: usersMe } = useUsersMeQuery();
    const displayedData = showAll ? data : data?.slice(0, 10);
    const [opened, { open, close }] = useDisclosure(false);

    const handleUpBalance = () => {
        open();
    }
    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.header}>
                    Top Up Balance
                </div>

                <div className={styles.headSection}>
                    UP Balance:&nbsp;&nbsp;
                    <span className={styles.many}>
                        {usersMe?.balance}$
                    </span>
                </div>
                <div className={styles.rechargeBalance}>
                    <Button content={"Recharge Balance"} onClick={handleUpBalance}></Button>
                </div>
                {
                    isMobile &&
                    <div className={clsx(styles.text, styles.textMobile)}>
                        If you’d like to top up using other methods, please write in the chat located in the far-right corner.
                    </div>
                }
                <div className={styles.headSection}>
                    Balance History
                </div>

                <div className={styles.table}>
                    <div className={clsx(styles.row, styles.headerRow)}>
                        <div className={styles.balance}>Balance</div>
                        <div className={styles.date}>Date</div>
                    </div>
                    <div className={styles.body}>
                        {displayedData?.length
                            ? displayedData.map((item) => (
                                <div className={styles.row} key={item.create_time + item.new_balance}>
                                    <div className={styles.balance}>{item.new_balance}$</div>
                                    <div className={styles.date}>
                                        {formateDate(item.create_time)}
                                    </div>
                                </div>
                            ))
                            : <div className={styles.noTransactions}>No transactions found</div>
                        }
                    </div>
                </div>

                {data && data?.length > 10 && (
                    <Button onClick={() => setShowAll(!showAll)} classNames="black" type="button" content={showAll ? 'Hide' : 'Show More'} />
                )}
                <Modal
                    opened={opened}
                    closeOnEscape={false}
                    closeOnClickOutside={false}
                    onClose={close}
                    title={<div className={styles.title}>{"Top Up Balance"}</div>}
                    size={isMobile ? 1200 : "lg"}
                    fullScreen={isMobile}
                    classNames={{
                        body: styles.modalBody,
                        header: styles.modalHeader,
                        content: styles.modalContent,
                        close: styles.modalCloseButton,
                        overlay: styles.modalOverlay
                    }}
                    transitionProps={{ transition: 'fade', duration: 200 }}
                >
                    <FormUpBalance close={close} />
                </Modal>
            </div>
            {
                !isMobile &&
                <div className={styles.text}>
                    If you’d like to top up using other methods, please write in the chat located in the far-right corner.
                </div>
            }
        </div>
    );
};
