import { Link } from 'react-router-dom';
import { Button, Input } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useLoginQuery } from 'lib/auth/useAuthorizationQuery';

import styles from './Login.module.scss';
import { RouteNames } from 'navigate/routeNames';

interface LoginFormInputs {
    email: string;
    password: string;
}

export const Login: React.FC = () => {
    const {
        mutate: sendLoginForm,
        isLoading
    } = useLoginQuery();
    const { control, handleSubmit, watch, formState: { errors } } = useForm<LoginFormInputs>();

    const email = watch("email", "");

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        if (!data.email || !data.password) return;
        sendLoginForm({ email: data.email, password: data.password });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.name}>STARKSTORE</div>
            <div className={styles.card}>
                <div className={styles.header}>Welcome!</div>
                <div className={styles.registration}>
                    Don't have an account? <Link to={"/registration"}>
                        <span className={styles.link}>&nbsp;Sign up</span>
                    </Link>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.inputs}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Email is required" }}
                        render={({ field }) => (
                            <Input
                                label="Email"
                                placeholder="Email"
                                type="email"
                                required
                                autoFocus={!email}
                                {...field}
                            />
                        )}
                    />
                    <p className={styles.errors}>{errors.email && errors.email.message}</p>
                    <Link to={RouteNames.PASSWORD_RESET} className={styles.passwordForgot}>
                        Forgot password?
                    </Link>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Password is required" }}
                        render={({ field }) => (
                            <Input
                                label="Password"
                                placeholder="***"
                                type="password"
                                required
                                {...field}
                            />
                        )}
                    />
                    <p className={styles.errors}>{errors.password && errors.password.message}</p>
                    <div className={styles.login}>
                        <Button type="submit" content="Log in" isLoading={isLoading} />
                    </div>
                </form>
            </div>
            <a href="https://freekassa.com" target="_blank" rel="noopener noreferrer">
                <img src="https://cdn.freekassa.com/banners/big-dark-1.png" title="Прием платежей" />
            </a>
        </div>
    );
};
