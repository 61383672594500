
import { FaTachometerAlt, FaBoxOpen, FaWallet, FaUser } from 'react-icons/fa';
import { useUsersMeQuery } from 'lib/users/useUsersMeQuery';
import { NavLink } from 'react-router-dom';
import { NavLink as NavLinkMantine } from '@mantine/core';
import React, { memo } from 'react';
import { ServicesT } from 'services/services.service';
import { useOrdersAllQuery } from 'lib/orders/useOrdersQuery';
import { API_URL } from 'consts';

import styles from './SideBar.module.scss';

const menuItems = [
    { name: 'My Services', path: '/', icon: <FaTachometerAlt size={20} /> },
    { name: 'Top Up Balance', path: '/top-up', icon: <FaWallet size={20} /> },
    { name: 'My Profile', path: '/profile', icon: <FaUser size={20} /> },
    { name: 'Add Service', path: '/orders', icon: <FaBoxOpen size={20} /> },
];


type Props = {
    list?: ServicesT[] | null
}

export const SideBar: React.FC<Props> = memo(({ list }) => {
    const { data: usersMe } = useUsersMeQuery();
    const { data: orders } = useOrdersAllQuery();

    const renderServiceIcon = (service: ServicesT
    ) => {
        if (service?.icon_svg) {
            return <div className={styles.iconSvg}>
                <div dangerouslySetInnerHTML={{ __html: service.icon_svg }} />
            </div>
        }
        if (service?.icon_service) {
            return <img className={styles.iconImg} src={`${API_URL}/${service.icon_service}`} alt={`${service.name} icon`} />;
        }
        return <div></div>;
    };

    return (
        <div className={styles.side}>
            <div className={styles.balance}>
                <div className={styles.card}>
                    Balance:&nbsp;&nbsp;
                    <span className={styles.many}>
                        {usersMe?.balance}$
                    </span>
                </div>
            </div>
            <ul className={styles.menu}>
                {menuItems.map((item) => {
                    return (
                        <li key={item.path}>
                            {item.path === '/orders' ? (
                                <NavLinkMantine
                                    leftSection={<FaBoxOpen size={20} />}
                                    label={<div className={styles.labelServices}>{item.name}</div>}
                                    classNames={{
                                        root: styles.mantineLink,
                                    }}
                                >
                                    {!!list?.length &&
                                        list.map((service) => {
                                            return (
                                                <NavLink
                                                    key={service.id}
                                                    to={`orders/${service.id}/${service.name}`}
                                                    className={({ isActive }) =>
                                                        isActive ? `${styles.active} ${styles.linkNav} ` : styles.linkNav
                                                    }
                                                >
                                                    <div className={styles.iconService}>
                                                        {renderServiceIcon(service)}
                                                    </div>
                                                    <span>{service.name}</span>
                                                </NavLink>
                                            );
                                        })}
                                </NavLinkMantine>
                            ) : (
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) =>
                                        isActive ? `${styles.active} ${styles.link} ` : styles.link
                                    }
                                >
                                    <span className={styles.icon}>{item.icon}</span>
                                    <span>{item.name}</span>
                                </NavLink>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

