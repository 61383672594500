import { Button, Input } from 'components';
import styles from './FormUpBalance.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateBalanceQuery } from 'lib/balance';
import { SendFormInputT } from 'services';
import toast from 'react-hot-toast';

type Props = {
    close: () => void;
};

export const FormUpBalance: React.FC<Props> = ({ close }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<SendFormInputT>();

    const {
        mutate: sendBalance, isLoading
    } = useUpdateBalanceQuery();


    const onSubmit: SubmitHandler<SendFormInputT> = (data) => {
        const balance = parseFloat(data.amount);
        if (balance > 0) {
            sendBalance({ amount: data.amount }, {
                onError: async (error: any) => {
                    const errorMessage = error?.response?.data?.detail || 'An unexpected error occurred.';
                    toast.error(errorMessage, { id: 'email-no-confirmation' });
                },
            });
        }
    };

    return (
        <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
            <h2>Up Your Balance</h2>
            <p className={styles.subtitle}>
                Please ensure the amount is valid and within your limits.
            </p>
            <Controller
                name="amount"
                control={control}
                defaultValue=""
                rules={{
                    required: "Balance is required",
                    validate: {
                        isNumber: (value) =>
                            /^\d+$/.test(value) || "Balance must be a valid number",
                        isPositive: (value) =>
                            Number(value) > 0 || "Balance must be greater than 0",
                    },
                }}
                render={({ field }) => (
                    <Input
                        label="Balance"
                        placeholder="Enter amount"
                        type="text"
                        required
                        autoFocus
                        {...field}
                        onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            // Убираем лидирующие нули
                            input.value = input.value.replace(/^0+(?=\d)/, '');
                            // Удаляем все символы, кроме цифр
                            input.value = input.value.replace(/[^0-9]/g, '');
                        }}

                    />
                )}
            />
            <p className={styles.errors}>
                {errors.amount && errors.amount.message}
            </p>
            
            <div className={styles.buttons}>
                <Button type="submit" content="Up Balance" isLoading={isLoading} />
                <Button onClick={close} type="button" classNames="black" content="Cancel" />
            </div>
        </form>
    );
};
