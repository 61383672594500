import styles from './Start.module.scss';
import { OrdersTable } from "./OrdersTable";
import { useOrdersAllQuery } from 'lib/orders/useOrdersQuery';
import { useAppSelector } from 'lib/useAppSelect';
import { OrdersCard } from './OrdersCard';
import { useServiceCategoriesTypeQuery, useServicePopularQuery } from 'lib/service/useServicesQuery';
import { CategoriesTypeT, PopularServicesT } from 'services/services.service';
import { API_URL } from 'consts';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FormProduct } from 'feature/orders/FormProduct';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

export const Start: React.FC = () => {
    const [ordering, setOrdering] = useState<string | undefined>();

    const handleFilterChange = (filter: string) => {
        setOrdering(filter); // Обновляем фильтр
    };

    const { isMobile } = useAppSelector((state) => state.isMobile);
    const { data: orders } = useOrdersAllQuery(ordering);
    const { data: popular } = useServicePopularQuery()
    const [opened, { open, close }] = useDisclosure(false);
    const [product, setProduct] = useState<CategoriesTypeT | null>()

    const renderServiceIcon = (service: PopularServicesT
    ) => {
        if (service?.icon) {
            return <div className={styles.iconSvg}>
                <div dangerouslySetInnerHTML={{ __html: service.icon }} />
            </div>
        }
        if (service?.icon_service) {
            return <img className={styles.iconImg} src={`${API_URL}/${service.icon_service}`} alt={`${service.category_name} icon`} />;
        }
        return <div></div>;
    };

    const { data: categoriesList, mutate: getService, isLoading, isError, reset } = useServiceCategoriesTypeQuery();

    const handleServiceNavigation = (id?: number, category_name?: string) => {
        if (!id || !category_name) return;

        reset();
        getService({ service_id: `${id}`, category: category_name }, {
            onError: (error: any) => {
                const errorMessage = error?.response?.data?.detail || 'An unexpected error occurred.';
                toast.error(errorMessage);
            }
        });


    };

    useEffect(() => {
        const item = categoriesList?.[0] || null;
        setProduct(item);
        if (item && !isError) open();
        return
    }, [categoriesList])


    return (
        <div className={styles.page}>
            <div className={styles.header}>
                My Services
            </div>
            {
                popular && popular?.length > 0 &&
                <div className={styles.headSection}>
                    Popular Services
                </div>
            }
            <div className={styles.services}>
                <>
                    {!!popular?.length &&
                        popular.map((service, index) => {
                            return (
                                <div
                                    key={service.id + index} className={styles.linkType}
                                    onClick={() => handleServiceNavigation(service?.service_id, service?.category_name)}
                                >
                                    <div className={styles.iconService}>
                                        {renderServiceIcon(service)}
                                    </div>
                                    <div className={styles.nameService}>{service.category_name}</div>
                                </div>
                            );
                        })}
                </>
            </div>
            {
                orders && orders?.length > 0 &&
                <div className={styles.headSection}>
                    Order List
                </div>
            }
            {!isMobile ? <OrdersTable orders={orders} ordering={ordering} handleFilterChange={handleFilterChange}/> : <OrdersCard orders={orders} />}

            <Modal
                opened={opened}
                closeOnEscape={false}
                closeOnClickOutside={false}
                onClose={close}
                title={<div className={styles.title}>{product?.service_name}</div>}
                size={product?.video_link ? 1200 : "lg"}
                fullScreen={isMobile}
                classNames={{
                    body: styles.modalBody,
                    header: styles.modalHeader,
                    content: styles.modalContent,
                    close: styles.modalCloseButton,
                    overlay: styles.modalOverlay,
                }}
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <FormProduct isLoading={isLoading} product={product} closeAll={close} />
            </Modal>

        </div>
    )
}
