import * as JivoSite from 'react-jivosite'
import styles from './JivositeOpen.module.scss'
import { MdOutlineMailOutline } from "react-icons/md";

export default function Jivosite() {
    return (
        <div onClick={() => jivo_api.open()}>
            <div className={styles.operator}>
                <div className={styles.wrapper}>
                    <MdOutlineMailOutline size={28}/>
                </div>
                <JivoSite.Widget id={process.env.REACT_APP_JIVO_CODE} />
            </div>
        </div>
    )
}