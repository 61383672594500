import React, { useState } from 'react';

import styles from './OrdersTable.module.scss';
import { AllOrdersT } from 'services/orders.service';
import { formateDate } from 'utils';
import clsx from 'clsx';
import { Button } from 'components';
import { HoverCard, Modal, Text } from '@mantine/core';
import { useOrdersGetQuery } from 'lib/orders/useOrdersQuery';

import { FaSortUp, FaSortDown } from 'react-icons/fa';

type Props = {
    orders?: AllOrdersT[];
    ordering?: string | undefined;
    handleFilterChange: (filter: string) => void;
};

export const OrdersTable: React.FC<Props> = ({ orders = [], handleFilterChange, ordering }) => {
    const [showAll, setShowAll] = useState(false);

    const generateFilter = (field: string) => (ordering === field ? `-${field}` : field);

    const displayedOrders = showAll ? orders : orders.slice(0, 5);

    const { mutate: getOrderId, reset, isSuccess, data: order } = useOrdersGetQuery();

    const handleClick = (id?: number) => {
        if (!id) return
        getOrderId(id)
    };

    const handleCloseModal = () => {
        reset();
    };

    return (
        <>
            <div className={styles.table}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            {[
                                { label: 'Id', field: '' },
                                { label: 'Service', field: 'service__name' },
                                { label: 'Period', field: 'period' },
                                { label: 'Quantity', field: 'quantity' },
                                { label: 'Service option', field: 'option__name' },
                                { label: 'Status', field: 'status' },
                                { label: 'Total price', field: 'total_price' },
                                { label: 'Created', field: 'created_at' },
                                { label: 'Completed', field: 'completed' },
                            ].map(({ label, field }) => {
                                const isCurrentField = ordering?.replace('-', '') === field; // Текущий столбец
                                const isDescending = ordering?.startsWith('-'); // Убывающий порядок

                                return (
                                    <th
                                        key={field}
                                        onClick={() =>
                                            label !== "Id" &&
                                            handleFilterChange(generateFilter(field))}
                                    >
                                        <div className={clsx(styles.tableHeaderStyle, { [styles.hoverHeaderStyle]: label !== "Id" })}>

                                            {label}
                                            {
                                                label !== "Id" &&
                                                <div className={styles.arrow}>
                                                    {isCurrentField && (
                                                        isDescending ? <FaSortDown className={styles.icon} /> : <FaSortUp className={styles.icon} />
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {displayedOrders.map((order) => (
                            <HoverCard width={380} withArrow openDelay={600} closeDelay={100} key={order.id}>
                                <HoverCard.Target>
                                    <tr className={styles.tableRowStyle} onClick={() => handleClick(order.id)}>
                                        <td className={styles.tableBodyStyle}>{order.id}</td>
                                        <td className={styles.tableBodyStyle}>{order.service}</td>
                                        <td className={styles.tableBodyStyle}>{order.period}</td>
                                        <td className={styles.tableBodyStyle}>{order.quantity}</td>
                                        <td className={clsx(styles.tableBodyStyle, styles.serviceOption)}>{order.service_option}</td>
                                        <td className={clsx(styles.tableBodyStyle, styles.block)}>
                                            <div className={clsx(styles.status, styles[`${order.status}`])}></div>
                                            <div>{order.status}</div>
                                        </td>
                                        <td className={styles.tableBodyStyle}>{order.total_price}&nbsp;$</td>
                                        <td className={styles.tableBodyStyle}>{formateDate(order.created_at)}</td>
                                        <td className={styles.tableBodyStyle}>{formateDate(order.completed)}</td>
                                    </tr>
                                </HoverCard.Target>
                                <HoverCard.Dropdown style={{
                                    backgroundColor: '#2A2A2A',
                                    color: 'white',
                                    borderRadius: "8px"
                                }}>
                                    <div>
                                        {order?.service && <Text size="sm">Service: {order.service}</Text>}
                                        {order?.period && <Text size="sm">Period: {order.period}</Text>}
                                        {order?.quantity && <Text size="sm">Quantity: {order.quantity}</Text>}
                                        {order?.service_option && <Text size="sm">Service option: {order.service_option}</Text>}
                                        {order?.status && <Text size="sm">Status: {order.status}</Text>}
                                        {order?.total_price && <Text size="sm">Total price: {order.total_price}&nbsp;$</Text>}
                                        {order?.created_at && <Text size="sm">Created: {formateDate(order.created_at)}</Text>}
                                        {order?.completed && <Text size="sm">Completed: {formateDate(order.completed)}</Text>}
                                        {
                                            order?.custom_data && Object.keys(order.custom_data).map((key) => (
                                                <Text size="md" key={key}>{key}: {order.custom_data[key]}</Text>
                                            ))
                                        }
                                    </div>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                    </tbody>
                </table>
            </div>
            {orders.length > 5 && (
                <div className={styles.buttonContainer}>
                    <Button onClick={() => setShowAll(!showAll)} classNames="black" type="button" content={showAll ? 'Hide' : 'Show More'} />
                </div>
            )}
            {isSuccess && (
                <Modal
                    opened={!!isSuccess}
                    closeOnClickOutside={false}
                    onClose={handleCloseModal}
                    size={500}
                    title={<div className={styles.title}>{order.service_name}</div>}
                    closeOnEscape={false}
                    classNames={{
                        body: styles.modalBody,
                        header: styles.modalHeader,
                        content: styles.modalContent,
                        close: styles.modalCloseButton,
                        overlay: styles.modalOverlay
                    }}
                    transitionProps={{ transition: 'fade', duration: 200 }}
                >
                    <div className={styles.modalWrapper}>
                        <div className={clsx(styles.block)}>
                            <div>Status&nbsp;&nbsp;</div>
                            <div className={clsx(styles.status, styles[`${order.status}`])}></div>
                        </div>
                        <Text size="md">Created: {formateDate(order.created_at)}</Text>
                        <Text size="md">Period: {order.period}</Text>
                        <Text size="md">Notes: {order.notes}</Text>
                        <Text size="md">Quantity: {order.quantity}</Text>
                        <Text size="md">Total price: {order.total_price}&nbsp;$</Text>
                        {order.interval && <Text size="md">Interval: {order.interval}&nbsp;$</Text>}
                        {
                            order.custom_data && Object.keys(order.custom_data).map((key) => (
                                <Text size="md" key={key}>{key}: {order.custom_data[key]}</Text>
                            ))
                        }
                    </div>
                </Modal>)}
        </>
    );
};


