import { AllOrdersT } from "services/orders.service";
import { formateDate } from "utils";
import { HoverCard, Modal, Text } from '@mantine/core';
import styles from './OrdersCard.module.scss'
import clsx from "clsx";
import { useOrdersGetQuery } from "lib/orders/useOrdersQuery";
type Props = {
    orders?: AllOrdersT[];
}

export const OrdersCard: React.FC<Props> = ({ orders }) => {

    const { mutate: getOrderId, reset, isSuccess, data: order } = useOrdersGetQuery();

    const handleClick = (id?: number) => {
        if (!id) return
        getOrderId(id)
    };

    const handleCloseModal = () => {
        reset();
    };

    return (
        <div className={styles.wrapper}>
            {orders && orders?.map((order) => (
                <div key={order.id} className={styles.card} onClick={() => handleClick(order.id)}>
                    <div className={clsx(styles.status, styles[`${order.status}`])}></div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Status:
                        </div>
                        <div className={styles.value}>
                            {order.status}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Service:
                        </div>
                        <div className={styles.value}>
                            {order.service}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Period:
                        </div>
                        <div className={styles.value}>
                            {order.period}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Quantity:
                        </div>
                        <div className={styles.value}>
                            {order.quantity}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Service option:
                        </div>
                        <div className={styles.value}>
                            {order.service_option}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Total price:
                        </div>
                        <div className={styles.value}>
                            {order.total_price}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Created:
                        </div>
                        <div className={styles.value}>
                            {formateDate(order.created_at)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.head}>
                            Completed:
                        </div>
                        <div className={styles.value}>
                            {formateDate(order.completed)}
                        </div>
                    </div>
                </div>
            ))}

            {isSuccess && (
                <Modal
                    opened={!!isSuccess}
                    closeOnClickOutside={false}
                    onClose={handleCloseModal}
                    size={500}
                    title={<div className={styles.title}>{order.service_name}</div>}
                    closeOnEscape={false}
                    fullScreen={true}
                    classNames={{
                        body: styles.modalBody,
                        header: styles.modalHeader,
                        content: styles.modalContent,
                        close: styles.modalCloseButton,
                        overlay: styles.modalOverlay
                    }}
                    transitionProps={{ transition: 'fade', duration: 200 }}
                >
                    <div className={styles.modalWrapper}>
                        <div className={clsx(styles.statusCard, styles[`${order.status}`])}></div>
                        <Text size="md">Status: {order.status}</Text>
                        <Text size="md">Created: {formateDate(order.created_at)}</Text>
                        <Text size="md">Period: {order.period}</Text>
                        <Text size="md">Notes: {order.notes}</Text>
                        <Text size="md">Quantity: {order.quantity}</Text>
                        <Text size="md">Total price: {order.total_price}&nbsp;$</Text>
                        <Text size="md">Interval: {order.interval}&nbsp;$</Text>
                        {
                            order.custom_data && Object.keys(order.custom_data).map((key) => (
                                <Text size="md" key={key}>{key}: {order.custom_data[key]}</Text>
                            ))
                        }
                    </div>
                </Modal>)}
        </div>
    )
}
