import { Button as MantineButton } from '@mantine/core';
import styles from './Button.module.scss';
import clsx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type Props = {
    onClick?: () => void;
    content?: string | JSX.Element;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    classNames?: "black"
    isLoading?: boolean;
}

export const Button: React.FC<Props> = ({ onClick, content, type = "button", isLoading, disabled, classNames = "" }) => {
    return (
        <MantineButton
            classNames={{ root: clsx(styles.button, styles[classNames]) }}
            disabled={disabled || isLoading}
            onClick={type === "button" && onClick ? onClick : undefined}
            type={type}
        >
            {
                isLoading ?
                    <div className={styles.productLoading}>
                        <AiOutlineLoading3Quarters className={styles.loadingIcon} />
                    </div>
                    :
                    content
            }
        </MantineButton>
    )
}
