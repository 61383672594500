import HttpService from './http.service';

export type ServicesT = {
  id?: number;
  name?: string;
  icon_service?: string | null;
  icon_svg?: string | null;
};

export type CategoriesT = {
  categories: string[];
};

export type CategoriesTypeT = {
  id: number;
  period?: string;
  category?: string;
  discount_percentage?: number;
  discounted_price?: number;
  points?: string[];
  price_per_unit?: string;
  required_field?: string[];
  interval?: any;
  video_link?: string;
  service_name?: string;
  admin_contact_message?: string;
  is_interval_required?: boolean;
};

export type CalcPriceT = {
  service_option_id: number;
  quantity: number;
};
export type PopularServicesT = {
  service_id: number;
  id: number;
  category_name: string;
  icon: string; // svg
  icon_service: string; // img
  service_name: string;
};

const Services = {
  servicesList: async () => {
    const { data }: { data: ServicesT[] } =
      await HttpService().get('service/services/');
    return data;
  },

  servicesCategories: async (service_id: string) => {
    const { data }: { data: { categories: string[] } } =
      await HttpService().get(`service/services/${service_id}/categories/`);
    return data;
  },
  servicesCategoryType: async (req: {
    service_id: string;
    category?: string;
  }) => {
    const { data }: { data: CategoriesTypeT[] } = await HttpService().get(
      `service/services/${req.service_id}/categories/${req.category}/`
    );
    return data;
  },
  servicesCalculatePrice: async (req: CalcPriceT) => {
    const { data }: { data: { total_price: number } } =
      await HttpService().post(`service/calculate-price/`, req);
    return data;
  },
  popularServices: async () => {
    const { data }: { data: PopularServicesT[] } = await HttpService().get(
      `/service/popular-services/`
    );
    return data;
  },
};

export default Services;
