import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSignupQuery } from 'lib/auth/useAuthorizationQuery';
import { SignupQueryT } from 'types/auth';

import styles from './Registration.module.scss';
import { RouteNames } from 'navigate/routeNames';

export const Registration: React.FC = () => {
    const {
        mutate: sendSignupForm,
        isLoading,
        data: messageLogin,
        isSuccess,
        isError
    } = useSignupQuery();
    const navigate = useNavigate();
    const { control, handleSubmit, watch, formState: { errors } } = useForm<SignupQueryT>();
    const password = watch("password", "");
    const [isPasswordFocused, setPasswordFocused] = useState(false);
    const onSubmit: SubmitHandler<SignupQueryT> = (data) => {
        if (!data.email || !data.password || !data.re_password) return;
        sendSignupForm(
            {
                email: data.email,
                password: data.password,
                re_password: data.re_password,
            },
        )
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.name}>STARKSTORE</div>
            {
                isSuccess && <div className={styles.card}>
                    <div className={styles.message}>
                        <div>
                            Thank you for registering!
                        </div>
                        <div className={styles.text}>
                            Please confirm your registration via the email we sent you. The message might be in your spam folder.
                        </div>
                    </div>
                    <Button onClick={() => navigate(RouteNames.START)} type="button" content={"Go to Login"} />
                </div>
            }
            {
                !isSuccess &&
                <>

                    <div className={styles.card}>
                        <div className={styles.header}>Welcome!</div>
                        <div className={styles.registration}>
                            Already have an account? <Link to={"/login"}>
                                <span className={styles.link}>&nbsp; Log in</span>
                            </Link>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className={styles.inputs}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Email is required" }}
                                render={({ field }) => (
                                    <Input
                                        label="Email"
                                        placeholder="Email"
                                        type="email"
                                        required
                                        autoFocus
                                        {...field}
                                    />
                                )}
                            />
                            <p className={styles.errors}>{errors.email && errors.email.message}</p>

                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Password is required",
                                    minLength: {
                                        value: 8,
                                        message: "Password must be at least 8 characters long"
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <Input
                                            label="Password"
                                            placeholder="***"
                                            type="password"
                                            required
                                            {...field}
                                            onFocus={() => setPasswordFocused(true)}
                                            onBlur={() => setPasswordFocused(false)}
                                        />
                                    </>
                                )}
                            />
                            <p className={styles.errors}>
                                {
                                    isPasswordFocused && password.length < 8 && (
                                        `Characters remaining: ${8 - password.length}`
                                    )
                                }
                                {!isPasswordFocused && errors.password && errors.password.message}
                            </p>
                            <Controller
                                name="re_password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Password confirmation is required",
                                    validate: {
                                        matchesPreviousPassword: (value) => {
                                            const passwordValue = watch("password");
                                            return value === passwordValue || "The passwords do not match";
                                        }
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Repeat the password"
                                        placeholder="***"
                                        type="password"
                                        required
                                        {...field}
                                    />
                                )}
                            />
                            <p className={styles.errors}>{errors.re_password && errors.re_password.message}</p>

                            <div className={styles.login}>
                                <Button type="submit" content="Sign Up" isLoading={isLoading} />
                            </div>
                        </form>
                    </div>
                </>}
        </div>
    );
};
