import { Modal } from '@mantine/core';
import React, { useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button, Input } from "components";

import styles from './PasswordProfile.module.scss';
import { useChangePasswordQuery } from 'lib/profile/useChangeProfileQuery';
import toast from 'react-hot-toast';

type ChangePasswordInputs = {
    current_password: string;
    new_password: string;
    re_password: string;
}

type Props = {
    opened: boolean;
    close: () => void
}

export const PasswordProfile: React.FC<Props> = ({ opened, close }) => {

    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm<ChangePasswordInputs>();
    
    const new_password = watch("new_password", "");
    
    const {
        mutate: sendChangePassword,
        isLoading,
    } = useChangePasswordQuery();

    const [isPasswordFocused, setPasswordFocused] = useState(false);

    const handleClose = () => {
        reset();
        close();
    };

    const onSubmit: SubmitHandler<ChangePasswordInputs> = (data) => {
        const { current_password, new_password } = data
        if (!new_password || !current_password) return
        sendChangePassword({ new_password, current_password }, {
            onSuccess: () => {
                toast.success("Your password has been successfully changed!");
                handleClose();
            },
            onError: (error: any) => {
                const errorMessage = error?.response?.data?.detail || 'An unexpected error occurred.';
                toast.error(errorMessage);
            }

        })
    };

    return (
        <Modal
            opened={opened}
            closeOnEscape={false}
            closeOnClickOutside={false}
            onClose={handleClose}
            title={<div className={styles.title}>
                Change Password
            </div>}
            size="sm"
            classNames={{
                body: styles.modalBody,
                header: styles.modalHeader,
                content: styles.modalContent,
                close: styles.modalCloseButton,
                overlay: styles.modalOverlay
            }}
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            <div className={styles.confirmation}>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <div>
                        <Controller
                            name="current_password"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Current password is required" }}
                            render={({ field }) => (
                                <Input
                                    label="Current password"
                                    placeholder="Enter your current password"
                                    type="password"
                                    {...field}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors.current_password?.message}</p>
                    </div>
                    <div>
                        <Controller
                            name="new_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "New password is required",
                                minLength: {
                                    value: 8,
                                    message: "Password must be at least 8 characters long"
                                }
                            }}
                            render={({ field }) => (
                                <Input
                                    label="New password"
                                    placeholder="Enter your new password"
                                    type="password"
                                    {...field}
                                    onFocus={() => setPasswordFocused(true)}
                                    onBlur={() => setPasswordFocused(false)}
                                />
                            )}
                        />
                        <p className={styles.errors}>
                            {
                                isPasswordFocused && new_password.length < 8 && (
                                    `Characters remaining: ${8 - new_password.length}`
                                )
                            }
                            {!isPasswordFocused && errors.new_password && errors.new_password.message}
                        </p>
                    </div>
                    <div>
                        <Controller
                            name="re_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Password confirmation is required",
                                validate: {
                                    matchesPreviousPassword: (value) => {
                                        const passwordValue = watch("new_password");
                                        return value === passwordValue || "The passwords do not match";
                                    }
                                }
                            }}
                            render={({ field }) => (
                                <Input
                                    label="Repeat the password"
                                    placeholder="***"
                                    type="password"
                                    required
                                    {...field}
                                />
                            )}
                        />
                        <p className={styles.errors}>{errors.re_password && errors.re_password.message}</p>
                    </div>
                    <div className={styles.buttons}>
                        <Button type="submit" content="Confirm" isLoading={isLoading}/>
                        <Button type="button" content="Cancel" onClick={handleClose} classNames={"black"} />
                    </div>
                </form>
            </div>
        </Modal>
    )
}
